import React, { FC, useContext, useEffect, useState } from 'react';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExtendedTheme, styled, theme } from '@cappex/theme';
import { appBarHeight } from '@cappex/constants';
import logo from '@common/assets/Appily-Favicon-48px.svg';
import SidebarContainer from '@common/components/SidebarContainer';
import { SwipeableDrawer, Typography, withStyles } from '@material-ui/core';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import AppBarMinHeight from '@common/components/AppBarMinHeight';
import Search from '@common/components/Search';
import Grid from '@material-ui/core/Grid';
import useComponentVisible from '@util/hooks/useComponentVisible';
import { ThemeProps } from 'styled-components';
import Hidden from '@material-ui/core/Hidden';
import { AutomationNameDefault, AutomationNameGeneric } from '@common/util/automation';
import { Route, Switch } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CollegeProfileCustomHeader from './CollegeProfileCustomHeader';
import { ModalContext, ModalPortal } from '../util/steps/components/ModalStepFlow';
import { enableIncentivization } from '@src/features/environment';
import Footer from './Footer';
import { BROWSE_GRADUATE_PROGRAMS_CONFIG } from '@src/features/graduateschoolssearch/constants/constants';
import HorizontalLogo from './HorizontalLogo';

const drawerWidth = 16.5;

const SmallIcon = styled.img`
	width: 1.5rem;
	height: 1.5rem;
`;

export interface MainContentWrapperProps {
	open: boolean;
}

const openTransition = (props: ThemeProps<ExtendedTheme>) => `
 transition: ${() =>
		props.theme.transitions.create('margin', {
			easing: props.theme.transitions.easing.sharp,
			duration: props.theme.transitions.duration.leavingScreen,
		})};
`;

const mainTransitionsOpen = `
  ${props => openTransition(props)};
  margin-left: ${drawerWidth}rem; 
`;

const MainContentWrapper: React.ComponentType<MainContentWrapperProps> = styled.div<
	MainContentWrapperProps
>`
	padding-bottom: 12.5rem;
	position: relative;
	min-height: calc(100vh - 13.5rem);
	flex-grow: 1;
	margin-top: ${appBarHeight};
	background-color: ${props => props.theme.palette.background.default};
	@media (min-width: ${props => props.theme.breakpoints.values.lg}px) {
		${() => mainTransitionsOpen}
		width: calc(100% - ${drawerWidth}rem);
	}
`;

const FooterWrapper = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
`;

const DrawerStyled = withStyles(drawerTheme => ({
	root: {
		[drawerTheme.breakpoints.up('lg')]: {
			flexShrink: 0,
		},
	},
	paper: {
		width: `${drawerWidth}rem`,
		borderRight: 0,
	},
}))(SwipeableDrawer);

const appTransitionsOpen = `
  padding-left: ${drawerWidth}rem;
  ${props => openTransition(props)};
`;

interface AppBarExtendedProps extends AppBarProps {
	open: boolean;
}

const AppBarStyled = styled(AppBar)<AppBarExtendedProps>`
	background-color: ${props => props.theme.palette.background.default};
	@media (min-width: ${props => props.theme.breakpoints.values.lg}px) {
		${() => appTransitionsOpen}
	}

	z-index: 1101;
	box-shadow: none;
	border-bottom: 1px solid ${props => props.theme.palette.divider};
`;

const IconButtonStyled = styled(IconButton)`
	margin-left: 0.75rem;
	margin-right: 1.25rem;
	@media (min-width: ${props => props.theme.breakpoints.values.lg}px) {
		display: none;
	}
`;

const GridItemFlexGrow = styled(Grid)`
	flex-grow: 1;
`;
const TitleWrapper = styled.div`
	color: ${props => props.theme.palette.text.primary};
	width: calc(100vw - 4.625rem);
	@media (min-width: ${props => props.theme.breakpoints.values.lg}px) {
		margin-left: 1rem;
		width: calc(100vw - ${drawerWidth + 1}rem);
	}
`;

const DashboardLogoWrapper = styled.div`
	display: flex;
	padding-right: 1rem;
	justify-content: center;
	@media (max-width: ${props => props.theme.breakpoints.values.lg}px) {
		padding-right: 4.4375rem; // width of hamburger icon + margin + padding to center correctly
	}
`;

export type LoggedInContainerProps = {
	automationNameDrawerButton?: string;
	automationNameSearch?: string;
	automationNameMobile?: string;
	automationNameDesktop?: string;
};

const LoggedInContainer: FC<LoggedInContainerProps> = ({
	children,
	automationNameDrawerButton = AutomationNameGeneric.burgerButton,
	automationNameSearch = AutomationNameGeneric.searchInput,
	automationNameMobile = AutomationNameDefault.drawerMobile,
	automationNameDesktop = AutomationNameDefault.drawerDesktop,
}) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
	const [open, setOpen] = useState(isLgUp);
	const { runFlow } = useContext(ModalContext);

	useEffect(() => {
		runFlow();
	}, [runFlow]);

	const handleSearchClose = () => {
		setIsComponentVisible(false);
	};

	const handleDrawerClose = () => {
		if (!isLgUp) {
			setOpen(false);
		}
	};

	return (
		<>
			<ModalPortal />
			<AppBarStyled position="fixed" open={open}>
				<div ref={ref}>
					<Grid container justifyContent="space-between">
						<GridItemFlexGrow item>
							<Grid container justifyContent="space-between">
								{!isComponentVisible && (
									<AppBarMinHeight disableGutters>
										<Switch>
											<Route
												path={['/college-profile', '/graduate-college-profile']}
												component={null}
											/>
											<Route
												path="/"
												render={() => (
													<IconButtonStyled
														color="default"
														aria-label="Open drawer"
														onClick={() => setOpen(!open)}
														data-qa={automationNameDrawerButton}
													>
														<FontAwesomeIcon icon={faBars} color="text.primary" />
													</IconButtonStyled>
												)}
											/>
										</Switch>
										<TitleWrapper>
											<Switch>
												<Route
													path="/dashboard"
													render={() => (
														<DashboardLogoWrapper>
															<Hidden mdDown>
																<SmallIcon src={logo} alt="Appily Logo" />
															</Hidden>
															<Hidden lgUp>
																<HorizontalLogo />
															</Hidden>
														</DashboardLogoWrapper>
													)}
												/>
												<Route
													path="/college-list"
													render={() => <Typography variant="h6">My College List</Typography>}
												/>
												<Route
													path="/college-planner"
													render={() => <Typography variant="h6">College Planner</Typography>}
												/>
												<Route
													path={[
														'/college-profile/:id/:slug',
														'/graduate-college-profile/:id/:slug',
													]}
													component={CollegeProfileCustomHeader}
												/>
												<Route
													path="/college-search"
													render={() => <Typography variant="h6">Colleges</Typography>}
												/>
												<Route
													exact
													path="/graduate-schools-search"
													render={() => (
														<Typography variant="h6">Graduate Schools and Programs</Typography>
													)}
												/>
												<Route
													exact
													path="/graduate-schools-search/:guidanceId"
													render={props => (
														<Typography variant="h6">
															{
																BROWSE_GRADUATE_PROGRAMS_CONFIG.guidancePages.find(
																	item => item.route === props.location.pathname
																)?.header
															}
														</Typography>
													)}
												/>
												<Route
													exact
													path="/graduate-schools-search/:guidanceId/:exploreId"
													render={props => (
														<Typography variant="h6">
															{
																BROWSE_GRADUATE_PROGRAMS_CONFIG.explorePages.find(
																	item => item.route === props.location.pathname
																)?.header
															}
															{
																BROWSE_GRADUATE_PROGRAMS_CONFIG.exploreAOSPages.find(
																	item => item.route === props.location.pathname
																)?.header
															}
														</Typography>
													)}
												/>
												<Route
													path="/scholarship-search"
													render={() => <Typography variant="h6">Find Scholarships</Typography>}
												/>
												<Route
													path="/match-direct"
													render={() => <Typography variant="h6">Direct Admissions</Typography>}
												/>
												<Route
													path="/college-preferences"
													render={() => <Typography variant="h6">My Preferences</Typography>}
												/>
												<Route
													path="/profile"
													render={() => <Typography variant="h6">My Student Profile</Typography>}
												/>
												<Route
													path="/settings"
													render={() => <Typography variant="h6">Settings</Typography>}
												/>
												<Route
													path="/offers"
													render={() => <Typography variant="h6">Featured Offers</Typography>}
												/>
												{enableIncentivization && (
													<Route
														path="/achievements"
														render={() => <Typography variant="h6">Achievements</Typography>}
													/>
												)}
												<Route path="/" render={() => <HorizontalLogo />} />
											</Switch>
										</TitleWrapper>
									</AppBarMinHeight>
								)}

								{isComponentVisible && (
									// TODO: Automation
									<Search
										handleSearchClose={handleSearchClose}
										automationName={automationNameSearch}
									/>
								)}
							</Grid>
						</GridItemFlexGrow>
					</Grid>
				</div>
			</AppBarStyled>
			<Hidden mdDown>
				<DrawerStyled
					disableSwipeToOpen
					variant="permanent"
					ModalProps={{ keepMounted: true }}
					open
					onClose={() => setOpen(false)}
					onOpen={() => setOpen(true)}
					data-qa={automationNameDesktop}
				>
					<SidebarContainer handleDrawerClose={handleDrawerClose} />
				</DrawerStyled>
			</Hidden>
			<Hidden lgUp>
				<DrawerStyled
					disableSwipeToOpen
					variant="temporary"
					ModalProps={{ keepMounted: true }}
					open={open}
					onClose={() => setOpen(false)}
					onOpen={() => setOpen(true)}
					data-qa={automationNameMobile}
					// this should be higher than the footer zIndex
					style={{ zIndex: 10001 }}
				>
					<SidebarContainer handleDrawerClose={handleDrawerClose} />
				</DrawerStyled>
			</Hidden>

			<MainContentWrapper open={open}>
				<main>{children}</main>
				<FooterWrapper>
					<Footer />
				</FooterWrapper>
			</MainContentWrapper>
		</>
	);
};

export default LoggedInContainer;
