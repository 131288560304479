import React, { FC, useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';
import LoggedInContainer from '@common/components/LoggedInContainer';
import { styled } from '@cappex/theme';
import GenericLinkExpired from '@src/common/components/GenericLinkExpired';
import LoadingComponent from '@src/common/components/LoadingComponent';
import SnackbarManager from '@src/common/components/SnackbarManager';
import GATracking from '@src/common/util/analytics/googleAnalytics';
import SmartScriptTracking from '@src/common/util/analytics/smartscriptAnalytics';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import OutreachProvider from '@src/common/util/outreach/components/OutreachProvider';
import ModalManager from '@src/common/util/steps/components/ModalStepFlow';
import StudentCollegeListProvider from '@src/common/util/studentcollege/components/StudentCollegeListProvider';
import LoadableAccountSuppression from '@src/features/accountsuppression';
import LoadableGenericNotFound from '@src/features/app/components/LoadableNotFound';
import LoadableCollegeListPage from '@src/features/collegelist';
import LoadableCollegePlannerPage from '@src/features/collegeplanner';
import LoadableCollegePreferencesPage from '@src/features/collegepreferences';
import LoadableCollegeProfilePage from '@src/features/collegeprofile';
import LoadableCollegeSearchLandingPage from '@src/features/collegesearch';
import LoadableDashboardPage from '@src/features/dashboard';
import LoadableMyAdmissionOffersPage from '@src/features/matchdirect';
import LoadableOfferPage from '@src/features/offer';
import ForgotPasswordContainer from '@src/features/forgotpassword';
import InviteManager from '@src/features/invite/containers/InviteManager';
import LoginContainer from '@src/features/login';
import LoadableProfilePage from '@src/features/profile';
import BASE_PROFILE_PATH from '@src/features/profile/constants/constants';
import RegistrationFlow from '@src/features/registration/containers/RegistrationFlow';
import ResetPasswordContainer from '@src/features/resetpassword';
import LoadableScholarshipSearchLandingPage from '@src/features/scholarshipsearch';
import LoadableSettingsPage from '@src/features/settings';
import StudentProvider from '@util/studentContext/components/StudentProvider';
import TrackingManager from './TrackingManager';
import OfferCardModalIframe from '@src/features/offer/components/OfferCardModalIframe';
import AcceptedOffersProvider from '@util/acceptedofferscontext/components/AcceptedOffersProvider';
import AchievementsPage from '@src/features/incentivization/main';
import { enableIncentivization } from '@src/features/environment';
import MagicLinkPage from '@src/features/magiclogin';
import HeapTracking from '@src/common/util/analytics/heapAnalytics';
import SnowplowTracker from './SnowplowTracker';
import useReloadCheck from '../util/useReloadCheck';
import EventBusManager from './EventBusManager';
import AppErrorBoundary from './AppErrorBoundary';

// Bring in and use our main font
WebFont.load({
	google: {
		families: ['Nunito Sans:400,700&display=swap'],
	},
});

const FontDiv = styled.div`
	font-family: '"Nunito Sans"', serif;
`;

const LoggedInComponent = () => {
	const { search } = useLocation();

	return (
		<LoggedInContainer>
			<InviteManager />
			<Switch>
				<Route exact path="/dashboard" component={LoadableDashboardPage} />
				<Route exact path="/college-list" component={LoadableCollegeListPage} />
				<Route exact path="/college-search" component={LoadableCollegeSearchLandingPage} />
				<Route exact path="/college-planner" component={LoadableCollegePlannerPage} />
				<Route exact path="/college-planner/:categoryId" component={LoadableCollegePlannerPage} />
				<Route
					exact
					path="/college-planner/:categoryId/:actionId"
					component={LoadableCollegePlannerPage}
				/>
				<Route exact path="/match-direct" component={LoadableMyAdmissionOffersPage} />
				<Route exact path="/scholarship-search" component={LoadableScholarshipSearchLandingPage} />
				<Route exact path="/college-preferences" component={LoadableCollegePreferencesPage} />
				<Route path="/college-profile/:id/:slug" component={LoadableCollegeProfilePage} />
				<Route path={BASE_PROFILE_PATH} component={LoadableProfilePage} />
				<Route exact path="/settings" component={LoadableSettingsPage} />
				<Route exact path="/suppress-account" component={LoadableAccountSuppression} />
				<Route exact path="/offers" component={LoadableOfferPage} />
				{enableIncentivization && <Route exact path="/achievements" component={AchievementsPage} />}
				<Route
					path="/"
					render={() => (
						<Redirect
							to={{
								pathname: '/dashboard',
								search,
							}}
						/>
					)}
				/>
			</Switch>
		</LoggedInContainer>
	);
};

const getLoggedInRoutes = () => (
	<StudentProvider key="logged-in">
		<StudentCollegeListProvider>
			<OutreachProvider>
				<AcceptedOffersProvider>
					<Switch>
						<Route path="/offer/pa/modal" component={OfferCardModalIframe} />
						<Route path="/" component={LoggedInComponent} />
					</Switch>
				</AcceptedOffersProvider>
			</OutreachProvider>
		</StudentCollegeListProvider>
	</StudentProvider>
);

const getRegIncompleteRoutes = () => (
	<StudentProvider key="reg-incomplete">
		<Switch>
			<Route path="/register" component={RegistrationFlow} />
			<Route path="/forgot-password" component={ForgotPasswordContainer} />
			<Route path="/reset-password" component={ResetPasswordContainer} />
			<Route
				path="/expired-link"
				render={() => <GenericLinkExpired to="/login" redirectLabel="Back to Login" />}
			/>
			<Route path="/suppress-account" component={LoadableAccountSuppression} />
			<Route path="/" render={() => <Redirect to="/register/core/main1" />} />
			<Route component={LoadableGenericNotFound} />
		</Switch>
	</StudentProvider>
);

const getLoggedOutRoutes = () => (
	<StudentProvider key="logged-out">
		<Switch>
			<Route path="/register" component={RegistrationFlow} />
			<Route exact path="/magic-login" component={MagicLinkPage} />
			<Route exact path="/login" component={LoginContainer} />
			<Route path="/forgot-password" component={ForgotPasswordContainer} />
			<Route path="/reset-password" component={ResetPasswordContainer} />
			<Route
				path="/expired-link"
				render={() => <GenericLinkExpired to="/login" redirectLabel="Back to Login" />}
			/>
			<Route component={LoadableGenericNotFound} />
		</Switch>
	</StudentProvider>
);

const getUnknownAuthRoute = () => (
	<Switch>
		<Route component={LoadingComponent} />
	</Switch>
);

const getRoutes = (authState: AuthenticState, registrationIsComplete: boolean) => {
	switch (authState) {
		case AuthenticState.Authentic:
			return registrationIsComplete ? getLoggedInRoutes() : getRegIncompleteRoutes();
		case AuthenticState.NotAuthentic:
			return getLoggedOutRoutes();
		case AuthenticState.Unknown:
		default:
			return getUnknownAuthRoute();
	}
};

const App: FC = () => {
	const { isAuthentic, registrationIsComplete } = useContext(AuthContext);
	useReloadCheck();
	return (
		<AppErrorBoundary>
			<FontDiv>
				<GATracking />
				<SmartScriptTracking />
				<SnowplowTracker />
				<HeapTracking />
				<TrackingManager />
				<SnackbarManager>
					<ModalManager>
						<EventBusManager />
						{getRoutes(isAuthentic, registrationIsComplete)}
					</ModalManager>
				</SnackbarManager>
			</FontDiv>
		</AppErrorBoundary>
	);
};
export default App;
