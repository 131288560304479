import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import checkLockout from '@util/lockout';
import getEndpoint from '@util/request';
import { Grid, Typography } from '@material-ui/core';
import { SubForm } from '@src/common/components/BaseValidationForm';
import GradDateInput from '@src/common/components/GradDateInput';
import HiddenInput from '@src/common/components/HiddenInput';
import MajorFormInput from '@src/common/components/MajorFormInput';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import StartTermInput from '@src/common/components/StartTermInput';
import { FormNames, ListFormNames } from '@cappex/constants';
import { styled } from '@cappex/theme';
import { AuthenticState } from '@src/common/util/auth';
import useAuthentication from '@src/common/util/auth/hooks/useAuthentication';
import useUserData from '@src/common/util/hooks/useUserData';
import request, {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	RequestSourceIdentifier,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import {
	StudentCollegeListTypeTrackingValue,
	StudentCollegeListLocationTrackingValue,
} from '@src/common/util/studentcollege/constants';
import { getStudentType, parseStudent, Student, StudentType } from '@util/student/studentDataUtil';
import { FormContext, FormFields } from '@util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import CurrentCollegeInput from '@src/common/components/CurrentCollegeInput';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';
import TransferTermInput from '@src/common/components/TransferTermInput';

const EMPTY_FUNCTION = () => {};

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

const StudentPersonalInfoPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		isAllowedUnauthentic,
		targetWhenUnauthentic,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getValue } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const isAuthentic = useAuthentication();
	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);
	const history = useHistory();

	const [userData, setUserData] = useState<Partial<Student>>({});

	const [studentType, setStudentType] = useState(StudentType.HIGH_SCHOOL);

	const transferStudent = String(
		getValue(FormNames.openToTransfer)[FormNames.openToTransfer]
	) as YesNoSelection;

	useEffect(() => {
		const newStudentType =
			getStudentType((getValue(FormNames.studentTypeId) as FormFields)?.studentTypeId) ||
			userData.studentTypeId;
		if (newStudentType && newStudentType !== studentType) {
			setStudentType(newStudentType);
		}
	}, [studentType, getValue, userData]);

	const [submitDisabled, setSubmitDisabled] = useState(false);

	// Get user info to pre-populate
	useEffect(() => {
		if (R.isNil(userInfo.roleId)) {
			return;
		}

		request<any>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data.response)
			.then(data => {
				setUserData(parseStudent(data));
			});
	}, [userInfo]);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	// The flow expects this page to only be hit
	if (
		!isAllowedUnauthentic &&
		isAuthentic !== AuthenticState.Authentic &&
		isAuthentic !== AuthenticState.Unknown &&
		active
	) {
		history.push(targetWhenUnauthentic);
	}

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<SubForm name="student">
					<SubForm name="studentHighSchoolDataForm">
						<Grid item>
							<GradDateInput
								required={active}
								defaultValue={{
									year: userData.highSchoolGradYear,
									month: userData.highSchoolGradMonth,
								}}
								label="High School Graduation Date"
							/>
						</Grid>
						<HiddenInput name={FormNames.requireHighSchoolId} initialValue="false" />
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<SubForm name="studentCollegeDataForm">
							<Grid item>
								<StartTermInput
									required={active}
									defaultValue={{
										year: userData.collegeStartYear,
										season: userData.collegeStartTermId,
									}}
									label="Expected College Start Term"
								/>
							</Grid>
						</SubForm>
					)}

					{studentType === StudentType.COLLEGE && (
						<SubForm name="studentCollegeDataForm">
							<Grid item>
								<CurrentCollegeInput label="Current College" placeholder="Find My College" flat />
							</Grid>
							<Grid item>
								<YesNoSelectorInput
									title="Are you considering transferring colleges?"
									defaultValue={YesNoSelection.NO_ANSWER}
									name={FormNames.openToTransfer}
									required={active}
								/>
							</Grid>
							{transferStudent === 'true' && (
								<>
									<Grid item>
										<TransferTermInput label="Expected Transfer Term" />
									</Grid>

									<Grid item>
										<SubForm distinct name="collegePreferences">
											<MajorFormInput
												label="Intended Major"
												subText="Select up to 5"
												name={ListFormNames.majorCipCodes}
											/>
										</SubForm>
									</Grid>
								</>
							)}
						</SubForm>
					)}
				</SubForm>

				{(studentType === StudentType.HIGH_SCHOOL || transferStudent === 'true') && (
					<Grid item xs={12}>
						<GutterBottomTypography variant="h6">
							Which colleges are you considering?
						</GutterBottomTypography>
						<SearchWrapper>
							<MultiCollegeInput
								zindexInner={studentType === StudentType.HIGH_SCHOOL ? 6 : 1}
								typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
								locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
							/>
						</SearchWrapper>
					</Grid>
				)}

				{studentType === StudentType.HIGH_SCHOOL && (
					<Grid item>
						<SubForm distinct name="collegePreferences">
							<MajorFormInput
								label="Majors"
								subText="Select up to 5"
								name={ListFormNames.majorCipCodes}
							/>
						</SubForm>
					</Grid>
				)}
				<Grid item>
					<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
						{currentStep === totalSteps ? 'Finish' : 'Next'}
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default StudentPersonalInfoPage;
