import * as R from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router-dom';
import BaseValidationForm from '@common/components/BaseValidationForm';
import EmailInput from '@common/components/EmailInput';
import PasswordInputValidation from '@common/components/PasswordInputValidation';
import { FormNames } from '@cappex/constants';
import getEndpoint from '@util/request';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import AuthContext from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import request, {
	ErrorMessages,
	JsonContentTypeHeader,
	RequestMethod,
	RequestResponse,
	UrlParameter,
} from '@cappex/request';
import {
	parseUser,
	PermissionsActionsMap,
	UserInfoWebResponse,
} from '@src/common/util/user/userUtil';
import InviteTokenUrlParam from '@src/features/invite/constants';
import LoginLinks from '@src/features/login/components/LoginLinks';
import { recaptchaSiteKey } from '@src/features/environment';
import { homeSection } from '@util/links/navigationLinks';
import withStyleOptions from '@util/style/styleOptions';
import { FormContext, FormFields, withFormValidation } from '@util/validation/form';
import { useCampaignOffersWithGetOffersCall } from '@src/common/util/hooks/useCampaignOffers';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import { RoleId } from '@src/common/util/roles/constants';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import OfferInterstitialModal from '@src/features/offer/containers/OfferInterstitialModal';
import { Placement } from '@src/features/offer/constants/constants';
import { styled, theme } from '@cappex/theme';
import RequestLinkBox, { getLinkRequest } from '@src/features/magiclogin/components/RequestLinkBox';
import SignInWithGoogleButton from '@src/common/components/SignInWithGoogleButton';
import {
	GOOGLE_BUTTON_FIXED_WIDTH,
	GOOGLE_BUTTON_FIXED_WIDTH_PX,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX,
	GOOGLE_BUTTON_SMALL_BREAKPOINT,
} from '../../../common/constants/signInWithGoogle';
import FeatureFlag from '@src/features/environment/components/FeatureFlag';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons/faSparkles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DividerWithChildren from '@src/common/components/DividerWithChildren';
import { AddCollegeToModalStepFlow } from '@common/components/AddCollegeToModalStepFlow';

const useStyles = makeStyles({
	passwordButton: {
		background: 'unset',
		border: 'unset',
		padding: 0,
		color: theme.palette.ink.dark,
		textDecoration: 'underline',

		'&:hover': {
			textDecoration: 'underline',
			cursor: 'pointer',
		},
	},
});

const widthStyles = `
width: ${GOOGLE_BUTTON_FIXED_WIDTH};
@media (max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT}) {
	width: ${GOOGLE_BUTTON_FIXED_WIDTH_SMALL};
}
`;

const StyledButton = styled(withStyleOptions(Button))`
	${widthStyles}
`;
const StyledTypography = styled(withStyleOptions(Typography))`
	${widthStyles}
`;

const WidthGrid = styled(Grid)`
	${widthStyles}
`;

const MarginGrid = styled(Grid)`
	margin: -1rem 0;
`;

const MarginTopTypography = styled(Typography)`
	margin-top: 1rem;
`;

const OFFER_INTERSTITIAL_MODAL_KEY = 'offer-interstitial-modal';

interface LoginProps {
	email?: string;
	emailLocked?: boolean;
	collegeIdToAdd?: string;
	externalLocation?: string;
	token?: LoginToken;
}

interface LoginToken {
	first: string;
	second: string;
}

type AuthParams = {
	roleId: RoleId;
	permissionsAndActionsMap: PermissionsActionsMap;
	isRegComplete: boolean;
	accountUuid: string;
};

const getParamsFromToken = (token: { first: string; second: string }) => {
	if (token.first && token.second) {
		return `?${InviteTokenUrlParam.first}=${token.first}&${InviteTokenUrlParam.second}=${token.second}`;
	}
	return '';
};

const LoginComponent: FC<LoginProps> = ({
	email = '',
	emailLocked = false,
	collegeIdToAdd,
	externalLocation,
	token,
}) => {
	const history = useHistory();
	const { setFormErrors, getFormValues } = useContext(FormContext);
	const { setValidAuth, attemptedLocation } = useContext(AuthContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { queueModal } = useContext(ModalContext);
	const xxsDown = useMediaQuery(`(max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT})`);
	const buttonWidth = xxsDown ? GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX : GOOGLE_BUTTON_FIXED_WIDTH_PX;
	const classes = useStyles();
	const buttonTextEmail = 'email';
	const buttonTextPass = 'password';

	const [emailValue, setEmailValue] = useState(email);
	const [loginWithEmail, setLoginWithEmail] = useState(true);
	const [displayMagicLinkTada, setDisplayMagicLinkTada] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [ctaButtonDisabled, setCtaButtonDisabled] = useState(false);
	const [isRememberMe, setRememberMe] = useState(false);
	const [authParams, setAuthParams] = useState<AuthParams>({
		roleId: undefined,
		permissionsAndActionsMap: undefined,
		isRegComplete: false,
		accountUuid: undefined,
	});
	const [isLoginComplete, setIsLoginComplete] = useState(false);
	const [urlParam, setUrlParam] = useState({});
	const [disable, setDisable] = useState<boolean>(false)

	const recaptchaRef = useRef<ReCAPTCHA>();
	const recaptcha = recaptchaRef.current;

	useEffect(() => {
		if (collegeIdToAdd) {
			setUrlParam({
				collegeId: collegeIdToAdd,
				externalLocation,
			});
		}
	}, [collegeIdToAdd, externalLocation]);

	const onCampaignOfferError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const [campaignOffers, getOffers, isCampaignOffersReady] = useCampaignOffersWithGetOffersCall(
		Placement.LOG_IN_INTERSTITIAL,
		onCampaignOfferError
	);

	const handleError = (newErrorMessage: string) => {
		setErrorMessage(newErrorMessage);
		setError(true);

		// email/pass fields should not be highlighted red if captcha error
		const isReCaptchaError = newErrorMessage && newErrorMessage.toLowerCase().includes('captcha');

		const formErrors: FormFields = {
			[FormNames.email]: isReCaptchaError ? '' : ' ',
			[FormNames.password]: isReCaptchaError ? '' : ' ',
		};

		setFormErrors(formErrors);

		setCtaButtonDisabled(false);
	};

	const afterLogin = (promise: Promise<RequestResponse<UserInfoWebResponse>>) => {
		promise
			.then(resp => {
				if (!R.isNil(resp) && resp.ok) {
					return resp.data;
				}
				throw new Error('Error retrieving login info');
			})
			.then(data => {
				if (data.meta.success && !R.isNil(data.response)) {
					const {
						roleId,
						permissionsAndActionsMap,
						completedRegistration,
						accountUuid,
					} = parseUser(data.response);

					if (collegeIdToAdd) {
						queueModal(QueueAction.PREPEND, 'AddCollegeToList', AddCollegeToModalStepFlow, {
							collegeIdToAdd,
							externalLocation,
						});
					}

					setAuthParams({
						roleId,
						permissionsAndActionsMap,
						isRegComplete: completedRegistration,
						accountUuid,
					});

					if (completedRegistration) {
						getOffers();
					}
				}

				setIsLoginComplete(true);
			})
			.catch(err => {
				if (err.response) {
					const { data } = err.response;
					if (!R.isNil(data.response)) {
						const lockedOut = checkLockout(data);
						if (!lockedOut) {
							handleError(data.response);
						}
					} else {
						handleError(data);
					}
				}
			});
	};

	const onGoogleLogin = (credentials: string) => {
		setCtaButtonDisabled(true);

		afterLogin(
			request<UserInfoWebResponse>({
				url: getEndpoint('/auth/sso/google/v1'),
				method: RequestMethod.POST,
				withCredentials: true,
				headers: [JsonContentTypeHeader],
				data: { credentials },
			})
		);
	};

	const onLogin = (reCaptchaToken: string) => {
		setCtaButtonDisabled(true);

		const params: UrlParameter[] = [{ name: 'rememberMe', value: isRememberMe }];

		afterLogin(
			request<UserInfoWebResponse>({
				url: getEndpoint('/auth/v1', params),
				method: RequestMethod.POST,
				withCredentials: true,
				headers: [JsonContentTypeHeader],
				data: {
					...getFormValues(),
					reCaptchaToken,
					uuid1: (token && token.first) || null,
					uuid2: (token && token.second) || null,
				},
			})
		);
		return true;
	};

	const onValid = () => {
		if (loginWithEmail) {
			getLinkRequest(emailValue, '', urlParam)();
			setDisplayMagicLinkTada(true);
			history.push(history.location.pathname);
		}
		recaptcha.reset();
		recaptcha.execute();
	};

	useEffect(() => {
		history.listen((_, action) => {
			if (action === 'POP' && displayMagicLinkTada) setDisplayMagicLinkTada(false);
		});
	}, [history, displayMagicLinkTada]);

	const onReCaptchaChange = (reCaptchaToken: string) => {
		if (reCaptchaToken != null) {
			onLogin(reCaptchaToken);
		}
	};

	const finalizeLogin = useCallback(() => {
		setValidAuth(
			authParams.roleId,
			authParams.permissionsAndActionsMap,
			authParams.isRegComplete,
			authParams.accountUuid
		);
		let target;
		if (!R.isNil(authParams.isRegComplete) && !authParams.isRegComplete) {
			target = '/register';
		} else if (attemptedLocation) {
			target = attemptedLocation;
		} else {
			target = homeSection.link;
		}

		history.push(`${target}${getParamsFromToken(token)}`);
	}, [authParams, history, setValidAuth, token, attemptedLocation]);

	const queueOfferInterstitialModal = useCallback(() => {
		if (!R.isEmpty(campaignOffers)) {
			queueModal(QueueAction.PREPEND, OFFER_INTERSTITIAL_MODAL_KEY, OfferInterstitialModal, {
				placement: Placement.LOG_IN_INTERSTITIAL,
				campaignOffers,
			});
		}
	}, [queueModal, campaignOffers]);

	useEffect(() => {
		if (isLoginComplete) {
			if (!authParams.isRegComplete) {
				finalizeLogin();
			} else if (isCampaignOffersReady && !R.isNil(campaignOffers) && authParams.isRegComplete) {
				queueOfferInterstitialModal();
				finalizeLogin();
			}
		}
	}, [
		isLoginComplete,
		campaignOffers,
		getOffers,
		isCampaignOffersReady,
		finalizeLogin,
		queueOfferInterstitialModal,
		authParams,
	]);

	return displayMagicLinkTada ? (
		<RequestLinkBox
			finish
			buttonConfig={{
				text: 'Resend link',
				icon: 'function',
			}}
			emailUuid=""
			email={emailValue}
			queryParams={urlParam}
		/>
	) : (
		<div>
			{/* <form> used with button - type submit so that hitting enter works */}
			<BaseValidationForm onValid={onValid} name="login">
				<Grid container spacing={5} direction="column" alignItems="center">
					<Grid item container spacing={1} direction="column" alignItems="center">
						<Grid item>
							<StyledTypography $boldFontWeight gutterBottom variant="h6">
								Sign in to Appily
							</StyledTypography>
						</Grid>
						{error && (
							<Grid data-qa="login-error" item>
								<StyledTypography color="error" variant="caption" paragraph>
									{errorMessage}
								</StyledTypography>
							</Grid>
						)}
						<WidthGrid item>
							<EmailInput
								variant="outlined"
								initialValue={email}
								disabled={emailLocked}
								fullWidth
								required
								onChange={setEmailValue}
							/>
						</WidthGrid>
						<WidthGrid item>
							{!loginWithEmail ? (
								<PasswordInputValidation variant="outlined" fullWidth hideValidationList />
							) : (
								<div />
							)}

							<FormControlLabel
								control={
									<Checkbox
										name={FormNames.rememberMe}
										data-qa="remember-me"
										color="secondary"
										onChange={e => setRememberMe(e.target.checked)}
									/>
								}
								label="Remember me"
							/>
						</WidthGrid>
					</Grid>
					<Grid item>
						<StyledButton
							type="submit"
							name="ctaButton"
							data-qa="cta-button"
							$boldFontWeight
							$noneTextTransform
							fullWidth
							disabled={ctaButtonDisabled}
							variant="contained"
							color="primary"
						>
							Sign in with {loginWithEmail ? buttonTextEmail : buttonTextPass}
						</StyledButton>

						{loginWithEmail && (
							<WidthGrid container direction="row" wrap="nowrap" spacing={1} alignItems="center">
								<Grid item>
									<FontAwesomeIcon
										fontSize="1.5rem"
										icon={faSparkles}
										color={theme.palette.text.secondary}
									/>
								</Grid>

								<Grid item>
									<MarginTopTypography variant="body2" color="textSecondary">
										We&apos;ll email you a magic link for a password-free sign-in. Or{' '}
										<Typography
											className={classes.passwordButton}
											data-qa="toggle-button"
											variant="body2"
											component="button"
											onClick={() => setLoginWithEmail(false)}
											name="toggleButtons"
											type="button"
										>
											sign in with a password
										</Typography>
									</MarginTopTypography>
								</Grid>
							</WidthGrid>
						)}
					</Grid>

					<MarginGrid item xs="auto" container direction="row" wrap="nowrap" alignItems="center">
						<DividerWithChildren>
							<Typography variant="body2" color="textSecondary" align="center">
								or
							</Typography>
						</DividerWithChildren>
					</MarginGrid>

					<Grid item container spacing={2} alignItems="center" direction="column">
						{!loginWithEmail && (
							<Grid item>
								<StyledButton
									name="toggleButtons"
									data-qa="toggle-button"
									$boldFontWeight
									$noneTextTransform
									fullWidth
									onClick={() => {
										setDisable(true)
										setLoginWithEmail(!loginWithEmail);
									}}
									variant="outlined"
									color="default"
									disabled={disable}
								>
									Sign in with email
								</StyledButton>
							</Grid>
						)}
						<FeatureFlag flag="enableGoogleSSO">
							<Grid item xs={12}>
								<SignInWithGoogleButton width={buttonWidth} onSignIn={onGoogleLogin} />
							</Grid>
						</FeatureFlag>
					</Grid>

					<Grid item xs={12}>
						<LoginLinks />
					</Grid>
				</Grid>
				<div data-qa="recaptcha">
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey={recaptchaSiteKey}
						onChange={e => onReCaptchaChange(e)}
						size="invisible"
					/>
				</div>
			</BaseValidationForm>
		</div>
	);
};

export default withFormValidation(LoginComponent);
