import { styled } from '@cappex/theme';
import { Grid, Link, LinkProps, Typography } from '@material-ui/core';
import React from 'react';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';

interface Props {
	usingCustomBackground?: boolean;
}

const StyledFooter = styled.footer<{ $usingCustomBackground: boolean; $onCollegePlanner: boolean }>`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin-top: 10rem;
	padding: 1rem 0;
	background-color: ${props => {
		if (props.$onCollegePlanner && !props.$usingCustomBackground) return 'rgba(0,0,0,0)';
		return props.$usingCustomBackground
			? props.theme.palette.text.primary
			: props.theme.palette.background.default;
	}};
	z-index: 10000;
`;

const StyledTypography = styled(Typography)<{ $darkMode: boolean }>`
	font-size: 14px;
	color: ${props => (props.$darkMode ? 'white' : props.theme.palette.ink.light)};
	display: inline-block;
	cursor: pointer;
`;

const FOOTER_LINKS: (LinkProps & { label: string })[] = [
	{
		label: 'Terms and Conditions',
		href: 'https://www.appily.com/terms-and-conditions',
		target: '_blank',
	},
	{ label: 'Privacy Policy', href: 'https://www.appily.com/privacy-policy', target: '_blank' },
	{
		label: 'California Privacy Notice',
		href: 'https://privacy.eab.com/appily-cs-tours-CA',
		target: '_blank',
	},
	{
		label: 'Do Not Sell or Share My Personal Information',
		href: 'https://www.appily.com/your-privacy-rights',
		target: '_blank',
	},
	{
		label: 'Privacy Preferences',
		onClick: () => {
			if (window.OneTrust) {
				window.OneTrust.ToggleInfoDisplay();
			}
		},
	},
];

const Footer: React.FC<Props> = ({ usingCustomBackground }) => {
	const history = useHistory();

	return (
		<StyledFooter
			$usingCustomBackground={usingCustomBackground}
			$onCollegePlanner={!history.location.pathname.includes('college-planner')}
		>
			<Grid container justifyContent="center">
				{R.flatten(
					FOOTER_LINKS.map(({ label, ...linkProps }) => [
						<Link key={label} {...linkProps}>
							<StyledTypography variant="body2" align="center" $darkMode={usingCustomBackground}>
								{label}
							</StyledTypography>
						</Link>,
						<StyledTypography key={`${label}-dot`} $darkMode={usingCustomBackground}>
							・
						</StyledTypography>,
					])
				).slice(0, -1)}
			</Grid>
		</StyledFooter>
	);
};

export default Footer;
